import React from 'react';
import Link from 'gatsby-link';
import { graphql } from 'gatsby'
import Layout from '../components/layout/Layout';

const BlogPage =({data}) => {

  let blogPosts = data.gcms.posts

  return (
    <Layout>
      <h1 className='container mx-auto my-4 text-4xl'>Digital Financial Blog</h1>
      {
        blogPosts.map(({id, coverImage, title, date, author, excerpt, slug}) => {
          return (
            <section className="container mx-auto flex lg:flex-row my-6" id={id}>
              <Link className="w-1/4 justify-start" to={slug}>
                <img  src={coverImage.url} alt="$" className="min-h-full"/>
              </Link>
                <div className="w-3/4 pl-16 flex flex-col justify-between" >
                <Link to={slug}>
                  <span><h2>{title}</h2></span>
                  <div className="italic font-thin text-gray-600">{excerpt}</div>
                </Link>

                <h3 className='text-1xl'>
                  <img className="inline-block h-6 w-6 mr-2 rounded-full text-white shadow-solid" src={author.picture.url} />
                  <span className="font-semibold">{author.name}, {author.title}</span>
                  <span className="italic font-thin text-gray-900">{' '.concat(date)}</span>
                </h3>
              </div>
            </section>
          )

        })

      }
    </Layout>
  );
};


export const query = graphql`
    query MyQ {
      gcms {
        posts(orderBy: publishedAt_DESC) {
          title
          excerpt
          content
          slug
          author {
            name
            title
            picture {
             url
            }
          }
          coverImage {
            url
          }
          date
        }
      }
    }

  `

export default BlogPage;
